<template>
    <div class="main">
        <div v-for="(item,index) in mxdata" :key="index" style="margin:10px 0;">
            <van-cell-group inset style="margin:0;box-shadow:4px 4px 10px #eee; border:1px solid #eee;">
                <van-cell center>
                    <template #title>
                        <div class="titleContent">
                            <div class="qjListItem">
                                <span class="spanTitle">业务类型：</span>
                                <span class="spanValue">{{item.YWLX}}</span>
                            </div>
                            <div class="qjListItem">
                                <span class="spanTitle">缴款金额：</span>
                                <span class="spanValue">{{item.YJSK}}</span>
                            </div>
                            <div class="qjListItem">
                                <span class="spanTitle">应补退税额：</span>
                                <div class="spanValue">{{item.YBTSE}}
                                </div>
                            </div>
                            <div class="qjListItem">
                                <span class="spanTitle">缴款状态：</span>
                                <span class="spanValue">{{item.JKZT}}</span>
                            </div>
                            <div class="qjListItem">
                                <span class="spanTitle">申报状态：</span>
                                <span class="spanValue">{{item.SBZT}}</span>
                            </div>
                            <div class="qjListItem">
                                <span class="spanTitle">申报期限：</span>
                                <span class="spanValue">{{item.SBQX}}</span>
                            </div>
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
    </div>
    <!-- 遮罩loading -->
    <van-overlay :show="showload" @click="showload = false" >
        <van-loading type="spinner" color="#fff" size="50px"  style="display: flex;align-items: center;justify-content: center;height: 100%;" />
    </van-overlay>
</template>

<script>
import { ref ,onMounted} from 'vue'
import loginRequest from '../../api/login'
import {Toast } from 'vant';
export default {
    name: "sbmx",
    setup() {
        const mxdata = ref([]);
        const showload = ref(false);
        const sbmxReq = ()=> {
            showload.value = true;
            loginRequest.sbmx_list({}).then(res=>{
                showload.value = false;
                if (res.returnCode == "00"){
                    // Toast(res.returnMessage);
                    console.log(res.rows);
                    mxdata.value=res.rows.mxData;
                    if(res.rows.mxData.length==0){
                        Toast('暂无数据')
                    }
                }
                
            }).catch(err=>{
                console.log(err);
                showload.value = false;
            });
        };

        onMounted(()=>{
            sbmxReq();
        });

        return{
            sbmxReq,
            mxdata,
            showload
        }
    }
}
</script>

<style scoped>
    .main {
        /* margin: 0; */
        padding:20px 10px;
        min-height: 100vh;
    }
    .qjListItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .spanTitle {
        min-width: 80px;
        color: #666;

    }
    .spanValue{
        text-align: right;
        color: #333;
    }
    
</style>